.btn-danger { border-radius: 8px !important; }

.planbtn { margin-top: 30px; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; }

.planbtn .btn:first-child { margin-right: 20px; }

.planchange-home .card-body { min-height: calc(100vh - 167px); position: relative; }

.planchange-home .card-body .text_underline { position: absolute; bottom: 30px; left: 0; right: 0; margin: 0 auto; display: inline-block; width: 144px; }

/* .planchange-home .plan-body { padding-bottom: 100px; } */

/* .p-planchange .card-body { padding-bottom: 80px; } */

.p-planchange .plan-tit { text-align: center; margin: 0 auto 40px; }

.p-planchange .plan-tit span { font-size: 22px; color: #02A3AF; padding: 13px 50px; border-radius: 20px; background: #E6F8F8; display: inline-block; }

.p-planchange .plan-head { display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -webkit-justify-content: space-between; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; background: #E6F8F8; margin-bottom: 20px; padding: 11px 20px; border-radius: 20px; }

.p-planchange .plan-head .current-plan { font-size: 22px; font-weight: 500; }

.p-planchange .plan-head .btn { background: #02A3AF; border-radius: 8px; }

.p-planchange .plan-body p { margin-bottom: 0; }

.p-planchange .plan-body .item { text-decoration: none; padding: 10px 20px; border-radius: 10px; display: grid; grid-template-columns: 30% 50% 20%; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -webkit-justify-content: space-between; -ms-flex-pack: justify; justify-content: space-between; cursor: pointer; height: 70px; background: #F2F5F5; position: relative; }

.p-planchange .plan-body .item:nth-of-type(n+2) { margin-top: 20px; }

.p-planchange .plan-body .item .tag { opacity: 0; position: absolute; top: -44px; left: -15px; }

.p-planchange .plan-body .item .tag .ins { background: #02A3AF; border-radius: 10px; width: 154px; height: 54px; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; position: relative; border-end-start-radius: 0; }

.p-planchange .plan-body .item .tag .ins::before { position: absolute; content: ""; width: 0; height: 0; border-style: solid; border-width: 0 18px 15px 0; border-color: transparent #02A3AF transparent transparent; left: 0; bottom: -15px; }

.p-planchange .plan-body .item .tag .ins img { margin-right: 10px; }

.p-planchange .plan-body .item .tag .ins span { color: #ffffff; }

.p-planchange .plan-body .plan-price { text-align: right; font-size: 16px; }

.p-planchange .plan-body .plan-price span { color: #FFB13D; font-weight: bold; font-size: 24px; }

.p-planchange .plan-body .plan-name { font-size: 16px; font-weight: bold; color: #02A3AF; line-height: 1.3; }

.p-planchange .plan-body .plan-info { font-size: 13px; }

.plancancle .plancancle-content { padding-top: 30px; }

.plancancle .plancancle-content h3 { font-size: 22px; font-weight: bold; text-align: center; margin-bottom: 30px; }

.plancancle .plancancle-content p { text-align: center; }

.plancancle .planbtn { margin-top: 40px; }

.plancancle #confirmCanclePlan .modal-header { -webkit-box-pack: end; -webkit-justify-content: flex-end; -ms-flex-pack: end; justify-content: flex-end; border-bottom: none; }

.plancancle #confirmCanclePlan .modal-header .close { background: none; }

.plancancle #confirmCanclePlan .modal-header .close svg path { fill: #02A3AF; }

.plancancle #confirmCanclePlan .modal-body { padding: 0; text-align: center; }

.plancancle #confirmCanclePlan .modal-body h3 { font-size: 24px; font-weight: bold; margin-bottom: 30px; }

.plancancle #confirmCanclePlan .modal-footer { padding-bottom: 50px; padding-top: 30px; border-top: none; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; }

.plancancle #confirmCanclePlan .modal-footer .btn { min-width: 180px; }

.plancancle #confirmCanclePlan .modal-footer .btn:first-child { margin-right: 20px; }

.plancancle #confirmCanclePlan .modal-footer > * { margin: 0; }

.planchoose .text-note { color: #ff0000; margin-bottom: 30px; padding-top: 10px; }

.planchoose .card-body { padding-bottom: 30px; }

@media all and (min-width: 1280px) { .planchoose .plan-body .item:not(.active):hover { background: #E6F8F8; color: #000000; }
  .planchoose .plan-body .item:not(.active):hover .plan-name { color: #000000; } }

.planchoose .plan-body .active { margin-top: 55px !important; }

.planchoose .plan-body .active .tag { opacity: 1; }
