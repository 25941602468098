/* alert */
.main_body { position: relative; }

.alert { opacity: 1; position: fixed; top: 10px; left: 0; right: 0; margin: 0 auto; width: 90%; max-width: 1200px; margin-bottom: 1rem; border: 1px solid transparent; border-radius: 0.25rem; z-index: 999; }

.alert .alert-content { padding: 0.75rem 1.25rem; position: relative; }

.alert .alert-content img { position: absolute; top: 10px; right: 10px; }

.alert .alert-content .close { position: absolute; top: 0; bottom: 0; right: 10px; margin: auto 0; cursor: pointer; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; }

.alert .alert-content .close path { fill: #004085; }

@media all and (min-width: 1280px) { .alert .alert-content .close:hover { -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg); -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; } }

.alert-primary { color: #004085; background-color: #cce5ff; border-color: #b8daff; }

.alert-success { color: #155724; background-color: #d4edda; border-color: #c3e6cb; }

.alert-success .alert-content .close path { fill: #155724; }

.alert-danger { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.alert-danger .alert-content .close path { fill: #721c24; }

.alert-secondary { color: #383d41; background-color: #e2e3e5; border-color: #d6d8db; }

.alert-secondary .alert-content .close path { fill: #383d41; }

/* popup */
.main_body { position: relative; }

.modal { display: none; }

.modal.showthis { position: fixed; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); z-index: 999; top: 0; bottom: 0; left: 0; right: 0; margin: auto; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; z-index: 2000; }

.modal.showthis h3 { text-align: center; font-size: 30px; margin-bottom: 5px; }

@media all and (max-width: 1024px) { .modal.showthis h3 { font-size: 26px; } }

@media all and (max-width: 639px) { .modal.showthis h3 { font-size: 24px; } }

.modal.showthis .modal-content { max-width: 650px; min-width: 518px; background: #ffffff; padding: 30px 70px 40px; border-radius: 20px; position: relative; }

.modal.showthis .modal-content .btn_close { width: 30px; height: 30px; background: #02A3AF; border-radius: 50%; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; cursor: pointer; position: absolute; top: 15px; right: 15px; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; }

@media all and (min-width: 1280px) { .modal.showthis .modal-content .btn_close:hover { -webkit-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg); -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; } }

@media all and (max-width: 639px) { .modal.showthis .modal-content .btn_close { width: 20px; height: 20px; top: 5px; right: 5px; } }

@media all and (max-width: 1024px) { .modal.showthis .modal-content { min-width: 500px; padding: 30px 30px 30px; } }

@media all and (max-width: 639px) { .modal.showthis .modal-content { max-width: 95%; min-width: 95%; padding: 20px 15px 20px; border-radius: 10px; } }

.modal.showthis .btn-payment { background: #02A3AF; width: 100%; height: 50px; font-size: 18px; color: #ffffff; border-radius: 5px; margin-top: 30px; }

@media all and (min-width: 1280px) { .modal.showthis .btn-payment:hover { opacity: 0.8; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; } }

@media all and (max-width: 1024px) { .modal.showthis .btn-payment { font-size: 16px; } }

@media all and (max-width: 639px) { .modal.showthis .btn-payment { font-size: 14px; } }

.modal.showthis .btn-payment[disabled] { background: #cccccc; pointer-events: none; }

#payment-infor .modal-content figure { margin-bottom: 20px; text-align: center; }

@media all and (max-width: 1024px) { #payment-infor .modal-content figure img { width: 80%; } }

#payment-infor .modal-content--ins { max-width: 400px; margin: 0 auto; }

#payment-infor .col > p, #payment-infor input { width: 100%; }

#payment-infor .col:nth-of-type(n+2) { margin-top: 15px; }

#payment-infor .col p { font-weight: 500; font-size: 18px; margin-bottom: 5px; display: inline-block; }

@media all and (max-width: 1024px) { #payment-infor .col p { font-size: 16px; } }

@media all and (max-width: 639px) { #payment-infor .col p { font-size: 14px; } }

#payment-infor .col input[type=text], #payment-infor .col input[type=password], #payment-infor .col input[type=number] { height: 50px; padding: 0 10px; border-radius: 5px; border: 1px solid #aaa; }

@media all and (max-width: 639px) { #payment-infor .col input[type=text], #payment-infor .col input[type=password], #payment-infor .col input[type=number] { height: 45px; } }

#payment-infor .col .group { display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: start; -webkit-justify-content: flex-start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; margin-bottom: 5px; }

#payment-infor .col .group p { margin-right: 30px; margin-bottom: 0; }

#payment-infor .col .group span { font-weight: 500; font-size: 18px; }

@media all and (max-width: 1024px) { #payment-infor .col .group span { font-size: 16px; } }

@media all and (max-width: 639px) { #payment-infor .col .group span { font-size: 14px; } }

#payment-infor .col .group .note-box { width: 25px; height: 25px; font-size: 14px; border-radius: 50%; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; border: 1px solid #aaaaaa; margin-left: 10px; cursor: pointer; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; }

@media all and (min-width: 1280px) { #payment-infor .col .group .note-box:hover { background: #02A3AF; color: #ffffff; -webkit-transition: 0.3s ease-in-out; transition: 0.3s ease-in-out; } }

@media all and (max-width: 639px) { #payment-infor .col .group .note-box { font-size: 14px; width: 20px; height: 20px; } }

#note-box h3 { margin-bottom: 20px; }

@media all and (max-width: 639px) { #note-box h3 { margin-bottom: 10px; } }

#note-box p { text-align: justify; }

#payment-method h3 { margin-bottom: 30px; }

#payment-method .listmethod { margin-bottom: 30px; }

#payment-method .listmethod li { display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; cursor: pointer; }

#payment-method .listmethod li .ic-check { margin-right: 35px; }

#payment-method .listmethod li .ic-check svg { height: 30px; width: 30px; }

#payment-method .listmethod li .ic-card { margin-right: 35px; }

#payment-method .listmethod li .ic-card svg { width:40px; }

#payment-method .listmethod li.active svg path { fill: #02A3AF; }

#payment-method .add-cart { text-align: center; display: block; font-weight: 500; font-size: 18px; }

@media all and (max-width: 639px) { #payment-method .add-cart { font-size: 16px; } }

.invalid-feedback { color: red; }

.num{font-size: 18px;}

#payment-infor .col .group span {
    position: relative;
}