/* 3D Viewer Management */
.threed-viewer-management {
  border: 1px dashed #d3d3d3;
  border-radius: 8px;
  padding: 1rem 1rem 5rem 1rem;
}
.threed-viewer-management-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.threed-viewer-management-container .jaw-upload-container {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  margin: 2rem 0;
}
.threed-viewer-management-container .jaw-upload-container figure {
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
}
.threed-viewer-management-container .jaw-upload-container label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.threed-viewer-management-container .jaw-upload-container input[type="file"] {
  display: none;
}
.threed-viewer-management-container .btn-primary {
  padding: 0.75rem 2rem;
}
/* 3D Viewer Matching */
.page-threed-viewer-matching #layoutSidenav_content {
  margin-left: 0 !important;
}
.threed-viewer-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: black;
  opacity: 0.8;
}
.threed-viewer-matching {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px dashed #d3d3d3;
  border-radius: 8px;
  padding: 1rem;
  z-index: 1;
}
.threed-viewer-matching-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.threed-viewer-matching-container .jaw-viewer-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.threed-viewer-matching-container .jaw-viewer-container .jaw-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.threed-viewer-matching-container .jaw-viewer-container .jaw-column .jaw-title {
  font-size: 0.65rem;
  background-color: #e5e5e5;
  padding: 0.25rem;
  border: 1px solid #c2c2c2;
}
.threed-viewer-matching-container .jaw-viewer-container .jaw-column .jaw-viewer {
  width: 100%;
  height: 100%;
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
.threed-viewer-matching-container .jaw-viewer-container .jaw-column .jaw-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.threed-viewer-matching-container .jaw-viewer-container .jaw-column .jaw-btn span {
  background-color: #e6f8f8;
  padding: 0.75rem 5rem;
  border-radius: 1rem;
  cursor: pointer;
}
.threed-viewer-matching-container .btn-primary,
.threed-viewer-matching-container .btn-secondary {
  padding: 0.75rem 2rem;
}
.threed-viewer-matching-container .btn-secondary {
  border-radius: 8px !important;
  margin-right: 1rem;
}

.jaw-viewer-editor {
  height: 100vh;
  background-color: #333333;
  position: relative;
}
.jaw-viewer-editor .editor-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10000;
}
.jaw-viewer-editor .editor-nav .nav-btn {
  height: 32px;
  color: #aaa;
  background-color: #222;
  border: 0px;
  margin: 0px;
  padding: 5px 8px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}
.jaw-viewer-editor .editor-nav .nav-btn.actived {
  color: #fff;
  background-color: #08f;
}
.jaw-viewer-editor .editor-nav .nav-btn img {
  width: 16px;
  filter: invert(1);
  opacity: 0.5;
}
.jaw-viewer-editor .nav-close {
  top: 1rem;
  right: 1rem;
  left: unset;
}
.jaw-viewer-editor .nav-close .nav-btn {
  background-color: white;
}
.jaw-viewer-editor .nav-save {
  top: unset;
  right: 1rem;
  bottom: 1rem;
  left: unset;
}

.modal-3d-edit-complete .modal-header {
  border: none;
}
.modal-3d-edit-complete .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.modal-3d-edit-complete .modal-body h6 {
  color: #656565;
}
.modal-3d-edit-complete .modal-body .btn-group2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal-3d-edit-complete .modal-footer {
  border: none;
}

.threed-viewer-popup {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.threed-viewer-matching-modal {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.threed-editor-modal {
  background-color: white;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

body:has(.threed-viewer-matching-modal),
body:has(.ModalImages.show) {
  overflow: hidden;
}

@media all and (max-width: 1024px) {
  .threed-viewer-matching-container .jaw-viewer-container {
    grid-template-columns: auto !important;
    grid-template-rows: 1fr 1fr 1fr !important;
  }
}
