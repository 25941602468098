@charset "UTF-8";
/*====================================================================================
■01.MARGIN DEFAULT
====================================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.m00 {
  margin: 0px !important;
}

.m05 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.m45 {
  margin: 45px !important;
}

.m50 {
  margin: 50px !important;
}

.m55 {
  margin: 55px !important;
}

.m60 {
  margin: 60px !important;
}

.mt00 {
  margin-top: 0px !important;
}

.mt01 {
  margin-top: 1px !important;
}

.mt02 {
  margin-top: 2px !important;
}

.mt03 {
  margin-top: 3px !important;
}

.mt04 {
  margin-top: 4px !important;
}

.mt05 {
  margin-top: 5px !important;
}

.mt06 {
  margin-top: 6px !important;
}

.mt07 {
  margin-top: 7px !important;
}

.mt08 {
  margin-top: 8px !important;
}

.mt09 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr00 {
  margin-right: 0px !important;
}

.mr05 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mb00 {
  margin-bottom: 0px !important;
}

.mb01 {
  margin-bottom: 1px !important;
}

.mb02 {
  margin-bottom: 2px !important;
}

.mb03 {
  margin-bottom: 3px !important;
}

.mb04 {
  margin-bottom: 4px !important;
}

.mb05 {
  margin-bottom: 5px !important;
}

.mb06 {
  margin-bottom: 6px !important;
}

.mb07 {
  margin-bottom: 7px !important;
}

.mb08 {
  margin-bottom: 8px !important;
}

.mb09 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.ml00 {
  margin-left: 0px !important;
}

.ml05 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}

/*====================================================================================
■PADDING DEFAULT
====================================================================================*/
.p00 {
  padding: 0px !important;
}

.p01 {
  padding: 1px !important;
}

.p02 {
  padding: 2px !important;
}

.p03 {
  padding: 3px !important;
}

.p04 {
  padding: 4px !important;
}

.p05 {
  padding: 5px !important;
}

.p06 {
  padding: 6px !important;
}

.p07 {
  padding: 7px !important;
}

.p08 {
  padding: 8px !important;
}

.p09 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.p40 {
  padding: 40px !important;
}

.p45 {
  padding: 45px !important;
}

.p50 {
  padding: 50px !important;
}

.p55 {
  padding: 55px !important;
}

.p60 {
  padding: 60px !important;
}

.pl00 {
  padding-left: 0px !important;
}

.pl05 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pr00 {
  padding-right: 0px !important;
}

.pr05 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pb00 {
  padding-bottom: 0px !important;
}

.pb05 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pt00 {
  padding-top: 0px !important;
}

.pt05 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

/*====================================================================================
■WIDTH DEFAULT
====================================================================================*/
.w5 {
  width: 5% !important;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w20 {
  width: 20% !important;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30% !important;
}

.w35 {
  width: 35% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w49 {
  width: 49% !important;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55% !important;
}

.w59 {
  width: 59% !important;
}

.w60 {
  width: 60% !important;
}

.w65 {
  width: 65% !important;
}

.w70 {
  width: 70% !important;
}

.w80 {
  width: 80% !important;
}

.w90 {
  width: 90% !important;
}

.w100 {
  width: 100% !important;
}

/*====================================================================================
■MARGIN% DEFAULT
====================================================================================*/
.ml1per {
  margin-left: 1%;
}

.ml2per {
  margin-left: 2%;
}

.ml3per {
  margin-left: 3%;
}

.ml4per {
  margin-left: 4%;
}

.mr1per {
  margin-right: 1%;
}

.mr2per {
  margin-right: 2%;
}

.mr3per {
  margin-right: 3%;
}

.mr4per {
  margin-right: 4%;
}

.mt1per {
  margin-top: 1%;
}

.mt2per {
  margin-top: 2%;
}

.mt3per {
  margin-top: 3%;
}

.mt4per {
  margin-top: 4%;
}

.mt5per {
  margin-top: 5%;
}

.mt6per {
  margin-top: 6%;
}

.mt7per {
  margin-top: 7%;
}

.mt8per {
  margin-top: 8%;
}

.mt9per {
  margin-top: 9%;
}

.mt10per {
  margin-top: 10%;
}

.pd1per {
  padding: 1%;
}

.pd2per {
  padding: 2%;
}

.pd3per {
  padding: 3%;
}

.pd4per {
  padding: 4%;
}

.pull_left {
  float: left;
}

.pull_right {
  float: right;
}

.text_left {
  text-align: left !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.text_justify {
  text-align: justify !important;
}

.text_nowrap {
  white-space: nowrap !important;
}

.text_lower {
  text-transform: lowercase !important;
}

.text_upper {
  text-transform: uppercase !important;
}

.text_capitalize {
  text-transform: capitalize !important;
}

.text_normal {
  font-weight: normal !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_line {
  text-decoration: underline;
}

.text_noline {
  text-decoration: none !important;
}

.text_over {
  overflow: hidden !important;
}

.fz10 {
  font-size: 10px !important;
}

.fz11 {
  font-size: 11px !important;
}

.fz12 {
  font-size: 12px !important;
}

.fz13 {
  font-size: 13px !important;
}

.fz14 {
  font-size: 14px !important;
}

.fz15 {
  font-size: 15px !important;
}

.fz16 {
  font-size: 16px !important;
}

.fz17 {
  font-size: 17px !important;
}

.fz18 {
  font-size: 18px !important;
}

.fz19 {
  font-size: 19px !important;
}

.fz20 {
  font-size: 20px !important;
}

.fz22 {
  font-size: 22px !important;
}

.fz24 {
  font-size: 24px !important;
}

.lhnm {
  line-height: normal !important;
}

.lh00 {
  line-height: 0 !important;
}

.lh12 {
  line-height: 1.2 !important;
}

.lh13 {
  line-height: 1.3 !important;
}

.lh14 {
  line-height: 1.4 !important;
}

.lh15 {
  line-height: 1.5 !important;
}

.lh16 {
  line-height: 1.6 !important;
}

.lh17 {
  line-height: 1.7 !important;
}

.lh18 {
  line-height: 1.8 !important;
}

.lh20 {
  line-height: 2 !important;
}

.lh23 {
  line-height: 2.3 !important;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.visible {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

.dispc,
.breakpc {
  display: block;
}

@media all and (max-width: 639px) {
  .dispc,
  .breakpc {
    display: none;
  }
}
.dissp,
.breaksp {
  display: block;
}

@media all and (min-width: 1200px) {
  .dissp,
  .breaksp {
    display: none;
  }
}
.flexFont {
  font-size: 100%;
}

.obj__fit,
.imgfit {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.overflow {
  overflow: hidden;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* purgecss start ignore */
/* purgecss end ignore */
@media all and (max-width: 639px) {
  .menu-open.navstate_show .type1 .nav-list > .nav-list-item {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
    animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(7) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(8) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(9) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(10) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(11) {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(12) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(13) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(14) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.menu-open.navstate_show .type1 .nav-list > .nav-list-item:nth-child(15) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@media all and (max-width: 639px) {
  .menu-open.navstate_show .type2 .nav-list > .nav-list-item {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(7) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(8) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(9) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(10) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(11) {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(12) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(13) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(14) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.menu-open.navstate_show .type2 .nav-list > .nav-list-item:nth-child(15) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@media all and (max-width: 639px) {
  .menu-open.navstate_show .type3 .nav-list > .nav-list-item > a {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
    animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(1) a {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(2) a {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(3) a {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(4) a {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(5) a {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(6) a {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(7) a {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(8) a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(9) a {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(10) a {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(11) a {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(12) a {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(13) a {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(14) a {
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

.menu-open.navstate_show .type3 .nav-list > .nav-list-item:nth-child(15) a {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item > a {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(1) a {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(2) a {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(3) a {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(4) a {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(5) a {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(6) a {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(7) a {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(8) a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(9) a {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(10) a {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(11) a {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(12) a {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(13) a {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(14) a {
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

.menu-open.navstate_hide .type1 .nav-list > .nav-list-item:nth-child(15) a {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item > a {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
  animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(1) a {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(2) a {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(3) a {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(4) a {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(5) a {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(6) a {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(7) a {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(8) a {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(9) a {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(10) a {
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(11) a {
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(12) a {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(13) a {
  -webkit-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(14) a {
  -webkit-animation-delay: 2.9s;
  animation-delay: 2.9s;
}

.menu-open.navstate_hide .type2 .nav-list > .nav-list-item:nth-child(15) a {
  -webkit-animation-delay: 3.1s;
  animation-delay: 3.1s;
}

.delay1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@media all and (max-width: 768px) {
  .delay1 {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }
}
.delay2 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@media all and (max-width: 768px) {
  .delay2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
}
.delay3 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@media all and (max-width: 768px) {
  .delay3 {
    -webkit-animation-delay: 0.45s;
    animation-delay: 0.45s;
  }
}
.delay4 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

@media all and (max-width: 768px) {
  .delay4 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
}
.delay5 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@media all and (max-width: 768px) {
  .delay5 {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
}
.delay6 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@media all and (max-width: 768px) {
  .delay6 {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
}
.delay7 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

@media all and (max-width: 768px) {
  .delay7 {
    -webkit-animation-delay: 1.05s;
    animation-delay: 1.05s;
  }
}
.delay8 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

@media all and (max-width: 768px) {
  .delay8 {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
}
.delay9 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

@media all and (max-width: 768px) {
  .delay9 {
    -webkit-animation-delay: 1.35s;
    animation-delay: 1.35s;
  }
}
.fixie body {
  overflow: visible;
}

.fixie .custom-object-fit,
.fixie .custom-object-contain {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
}

.fixie .custom-object-fit img,
.fixie .custom-object-contain img {
  opacity: 0;
}

.fixie .custom-object-fit {
  background-size: cover;
}

.fixie .custom-object-contain {
  background-size: contain;
}

.beforeload {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.gono-preload {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 99999;
  text-align: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  display: table;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9),
    -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition-delay: 0;
  -webkit-transition-delay: 0;
}

.gono-preload .gonoinside {
  display: table-cell;
  vertical-align: middle;
}

.gono-preload .gonoinside .svgsection {
  margin-top: -70px;
}

.gono-preload .gonoinside .svgsection svg {
  height: 160px !important;
}

.gono-preload .progress span {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background: #fff;
  height: 4px;
  width: 4px;
  display: inline-block;
  border-radius: 10px;
  -webkit-animation: wave 3s ease infinite;
  animation: wave 3s ease infinite;
}

.gono-preload .progress span:nth-child(1) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

.gono-preload .progress span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.gono-preload .progress span:nth-child(3) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.gono-preload .progress span:nth-child(4) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.gono-preload .progress span:nth-child(5) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.gono-preload .progress span:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.gono-preload .progress span:nth-child(7) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.gono-preload .progress span:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.pageloaded .gono-preload {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  height: 100%;
  background: #fff;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9),
    -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition-delay: 0;
  -webkit-transition-delay: 0;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 0.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 0.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.6s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 0.6s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.8s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 0.8s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 1s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 1.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
  animation: opacity 1.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.gono-loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  margin: 0 auto;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #333;
  -webkit-animation: gonoloader 0.8s infinite linear;
  animation: gonoloader 0.8s infinite linear;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

@-webkit-keyframes gonoloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes gonoloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: #eee;
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: #000;
  }
}
@keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: #eee;
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: #000;
  }
}
.header_top_logo {
  position: relative;
  z-index: 1;
}

.header_top_logo a {
  line-height: 0;
}

@media all and (max-width: 639px) {
  .header_top_logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
  }
}
.header_top_icon {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header_top_menu .header_nav {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: inherit;
}

.header_nav {
  position: relative;
  z-index: 98;
}

@media all and (min-width: 1200px) {
  .header_nav .header_nav_inside {
    min-width: inherit;
  }
}
.header .icon {
  margin-left: 5px;
}

@media all and (max-width: 639px) {
  .header .icon {
    margin-left: 10px;
  }
}
.header .icon_inside,
.header .icon-hamburger {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 57px;
  height: 57px;
  background: #02a3af;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media all and (min-width: 1200px) {
  .header .icon_inside:hover,
  .header .icon-hamburger:hover {
    background: #03d2e1;
    -webkit-transition: -webkit-background 0.3s cubic-bezier(0.26, 0.8, 0.62, 0.9);
    transition: -webkit-background 0.3s cubic-bezier(0.26, 0.8, 0.62, 0.9);
    -webkit-transition: background 0.3s cubic-bezier(0.26, 0.8, 0.62, 0.9);
    transition: background 0.3s cubic-bezier(0.26, 0.8, 0.62, 0.9);
    transition-delay: 0;
    -webkit-transition-delay: 0;
  }
}
@media all and (max-width: 520px) {
  .header .icon_inside,
  .header .icon-hamburger {
    width: 44px;
    height: 44px;
  }
}
.header .icon-hamburger {
  z-index: 99;
}

.header .icon-hamburger .icon-bar span {
  left: 0;
  height: 2px;
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.header .icon-hamburger .icon-bar span:first-child {
  top: 0;
}

.header .icon-hamburger .icon-bar span:nth-child(2) {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.header .icon-hamburger .icon-bar span:last-child {
  bottom: 0;
}

.header .icon-hamburger.type3 .icon-bar span:nth-child(1) {
  left: 0;
}

.header .icon-hamburger.type3 .icon-bar span:nth-child(1),
.header .icon-hamburger.type3 .icon-bar span:nth-child(3) {
  width: 70%;
}

.header .icon-hamburger.type3 .icon-bar span:nth-child(3) {
  right: 0;
}

.header .icon-img,
.header .icon-bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38%;
  width: 56%;
}

.header .icon-img img,
.header .icon-bar img {
  max-width: 30px;
}

.header .icon-txt {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  bottom: 0;
}

@media all and (max-width: 639px) {
  .header .icon-txt {
    font-size: 11px;
  }
}
@media all and (max-width: 420px) {
  .header .icon-txt {
    font-size: 9px;
  }
}
.header .hastext_true .icon-img,
.header .hastext_true .icon-bar {
  top: -6px;
}

.header .hastext_true .icon-img img,
.header .hastext_true .icon-bar img {
  max-height: 25px;
}

@media all and (max-width: 520px) {
  .header .hastext_true .icon-img img,
  .header .hastext_true .icon-bar img {
    max-width: 24px;
  }
}
.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 97;
}

.menu-open {
  position: relative;
}

.menu-open.navstate_show .header_nav.show .header_nav_inside {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.menu-open.navstate_show .header_nav.type2 .header_nav_inside {
  top: 0;
}

.menu-open.navstate_show .header_nav.type3 .header_nav_inside {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.menu-open.navstate_show .icon-hamburger .icon-bar span:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(-225deg);
  transform: translateY(9px) rotate(-225deg);
}

@media all and (max-width: 520px) {
  .menu-open.navstate_show .icon-hamburger .icon-bar span:nth-child(1) {
    -webkit-transform: translateY(7px) rotate(-225deg);
    transform: translateY(7px) rotate(-225deg);
  }
}
.menu-open.navstate_show .icon-hamburger .icon-bar span:nth-child(2) {
  -webkit-transform: translateY(0) rotate(225deg);
  transform: translateY(0) rotate(225deg);
  width: 100%;
}

.menu-open.navstate_show .icon-hamburger .icon-bar span:nth-child(3) {
  opacity: 0;
}

.menu-open.navstate_show .icon-hamburger.type2 .icon-bar span:nth-child(2) {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.menu-open.navstate_show .icon-hamburger.type2 .icon-bar span:nth-child(3) {
  -webkit-transform: translateY(-9px) rotate(225deg);
  transform: translateY(-9px) rotate(225deg);
  opacity: 1;
}

@media all and (max-width: 520px) {
  .menu-open.navstate_show .icon-hamburger.type2 .icon-bar span:nth-child(3) {
    -webkit-transform: translateY(-7px) rotate(225deg);
    transform: translateY(-7px) rotate(225deg);
  }
}
.menu-open.navstate_show .icon-hamburger.type3 .icon-bar span {
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.menu-open.navstate_show .icon-hamburger.type3 .icon-bar span:nth-child(1) {
  width: 100%;
}

.menu-open.navstate_hide .header_nav {
  opacity: 1;
  visibility: visible;
}

.menu-open.navstate_hide .header_nav.type1:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.menu-open.navstate_hide .header_nav.type2 {
  right: 0;
}

.menu-open.navstate_hide .header_nav.type3 {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.menu-open.navstate_hide .icon-hamburger .icon-bar span:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(-225deg);
  transform: translateY(9px) rotate(-225deg);
}

@media all and (max-width: 520px) {
  .menu-open.navstate_hide .icon-hamburger .icon-bar span:nth-child(1) {
    -webkit-transform: translateY(7px) rotate(-225deg);
    transform: translateY(7px) rotate(-225deg);
  }
}
.menu-open.navstate_hide .icon-hamburger .icon-bar span:nth-child(2) {
  -webkit-transform: translateY(0) rotate(225deg);
  transform: translateY(0) rotate(225deg);
  width: 100%;
}

.menu-open.navstate_hide .icon-hamburger .icon-bar span:nth-child(3) {
  opacity: 0;
}

.menu-open.navstate_hide .icon-hamburger.type2 .icon-bar span:nth-child(2) {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.menu-open.navstate_hide .icon-hamburger.type2 .icon-bar span:nth-child(3) {
  -webkit-transform: translateY(-9px) rotate(225deg);
  transform: translateY(-9px) rotate(225deg);
  opacity: 1;
}

@media all and (max-width: 520px) {
  .menu-open.navstate_hide .icon-hamburger.type2 .icon-bar span:nth-child(3) {
    -webkit-transform: translateY(-7px) rotate(225deg);
    transform: translateY(-7px) rotate(225deg);
  }
}
@media all and (min-width: 1200px) {
  .menu-open.navstate_hide .icon-hamburger.type2 .icon-bar span:nth-child(3) {
    -webkit-transform: translateY(-10px) rotate(225deg);
    transform: translateY(-10px) rotate(225deg);
  }
}
.menu-open.navstate_hide .icon-hamburger.type3 .icon-bar span {
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.menu-open.navstate_hide .icon-hamburger.type3 .icon-bar span:nth-child(1) {
  width: 100%;
}

@media all and (min-width: 1200px) {
  .nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media all and (max-width: 639px) {
  .nav-list {
    height: auto;
    padding: 0 3%;
    overflow: hidden;
  }
}
@media all and (max-width: 639px) {
  .nav-list-item {
    border-bottom: 1px solid #ccc;
  }
}
.nav-list-item a {
  font-size: 15px;
  line-height: 1;
  color: #02a3af;
  text-decoration: none;
  display: block;
  padding: 15px 0;
  text-align: center;
}

@media all and (max-width: 639px) {
  .nav-list-item a {
    padding: 20px 0;
    text-align: left;
  }
}
@media all and (min-width: 1200px) {
  .nav-list-item a:hover {
    opacity: 0.8;
  }
}
.header_nav_dropdown .nav-list > li.dropdown {
  position: relative;
}

.header_nav_dropdown .nav-list .childmenu {
  display: none;
  overflow: hidden;
}

@media all and (min-width: 1200px) {
  .header_nav_dropdown .nav-list .childmenu {
    position: absolute;
    left: 0;
    z-index: 9;
    background: #f1f1f1;
    min-width: 200px;
  }
}
@media all and (max-width: 639px) {
  .header_nav_dropdown .nav-list .childmenu li:first-child {
    border-top: 1px solid #ccc;
  }

  .header_nav_dropdown .nav-list .childmenu li:last-child {
    border-bottom: none;
  }
}
.header_nav_dropdown .nav-list .childmenu li a {
  text-align: left;
  padding: 15px 10px;
}

@media all and (max-width: 639px) {
  .header_nav_dropdown .nav-list .megamenu > a {
    position: relative;
  }

  .header_nav_dropdown .nav-list .megamenu > a::before,
  .header_nav_dropdown .nav-list .megamenu > a::after {
    position: absolute;
    content: "";
    background: #000;
    right: 20px;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .header_nav_dropdown .nav-list .megamenu > a::before {
    width: 14px;
    height: 2px;
    right: 1px;
  }

  .header_nav_dropdown .nav-list .megamenu > a::after {
    height: 14px;
    width: 2px;
    right: 7px;
  }

  .header_nav_dropdown .nav-list .megamenu > a.open::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.header_nav_dropdown .nav-list .megamenu_inside {
  width: 100%;
}

@media all and (min-width: 1200px) {
  .header_nav_dropdown .nav-list .megamenu_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media all and (min-width: 1200px) {
  .header_nav_dropdown .nav-list .megamenu_col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    padding: 10px 20px;
  }
}
@media all and (max-width: 639px) {
  .header_nav_dropdown .nav-list .megamenu_col li a {
    padding-left: 20px;
  }
}
.header_nav_dropdown .nav-list .megamenu_title {
  color: #000;
  font-size: 16px;
  display: block;
  line-height: 1;
  text-align: left;
}

@media all and (min-width: 1200px) {
  .header_nav_dropdown .nav-list .megamenu_title {
    padding: 10px 0;
  }
}
@media all and (max-width: 639px) {
  .header_nav_dropdown .nav-list .megamenu_title {
    padding: 15px 10px;
  }
}
.navstate_show .header_nav .nav-list {
  background: rgba(2, 163, 175, 0.75);
}

@media all and (min-width: 1200px) {
  .navstate_show .header_nav .nav-list {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav_inside {
    position: absolute;
    left: -100%;
    top: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav.type2 .header_nav_inside {
    left: 0;
    top: -10vh;
    right: 0;
    -webkit-transition-timing-function: cubic-bezier(-0.6, -0.28, 0.735, 0.045);
    transition-timing-function: cubic-bezier(-0.6, -0.28, 0.735, 0.045);
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav.type3 .header_nav_inside {
    left: 0;
    right: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s;
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav.type3 .nav-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: auto;
    background: #000;
    padding: 5vh 0 20vh;
    height: calc(100vh - 60px);
  }
}
@media all and (max-width: 639px) and (max-width: 520px) {
  .navstate_show .header_nav.type3 .nav-list {
    height: calc(100vh - 44px);
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav.type3 .nav-list-item {
    overflow: hidden;
    border: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media all and (max-width: 639px) {
  .navstate_show .header_nav.type3 .nav-list-item a {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #fff;
  }
}
@media all and (min-width: 1200px) {
  .navstate_show .icon-hamburger {
    display: none;
  }
}
.navstate_hide {
  overflow: hidden;
}

.navstate_hide .header_nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navstate_hide .header_nav .nav-list {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.navstate_hide .header_nav .nav-list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  overflow: hidden;
  margin: 30px 0;
  border: 0;
}

.navstate_hide .header_nav .nav-list-item a {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  padding: 0;
  padding-top: 5px;
}

.navstate_hide .header_nav_inside {
  position: relative;
  z-index: 9;
}

.navstate_hide .header_nav.type1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navstate_hide .header_nav.type1:before {
  content: "";
  position: absolute;
  background: rgba(2, 163, 175, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.navstate_hide .header_nav.type2 {
  left: inherit;
  right: -35%;
  width: 35%;
  background: #02a3af;
  -webkit-transition: -webkit-all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition: -webkit-all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  -webkit-transition: all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition: all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition-delay: 0;
  -webkit-transition-delay: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media all and (min-width: 1200px) {
  .navstate_hide .header_nav.type2 {
    max-width: 400px;
  }
}
@media all and (max-width: 639px) {
  .navstate_hide .header_nav.type2 {
    width: 100%;
    right: -100%;
  }
}
.navstate_hide .header_nav.type2 .header_nav_inside:before {
  content: "";
  position: fixed;
  background: rgba(2, 163, 175, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: -1;
}

.navstate_hide .header_nav.type3 {
  -webkit-transition: -webkit-all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition: -webkit-all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  -webkit-transition: all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition: all 0.5s cubic-bezier(0, 0.2, 0.17, 0.98);
  transition-delay: 0;
  -webkit-transition-delay: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(2, 163, 175, 0.9);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.navstate_hide.menu_fixed header .icon-hamburger.type1,
.navstate_hide.menu_fixed header .icon-hamburger.type2 {
  position: fixed;
  right: 0;
  top: 0;
}

.footer-bottom p {
  font-size: 14px;
  background: #e8e8e8;
  text-align: center;
  padding: 10px 0;
}

.footer .backtop {
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 8;
  bottom: 22px;
  right: 0;
}

.footer .backtop-icon {
  cursor: pointer;
  display: block;
  position: absolute;
  bottom: 0;
  right: 25px;
  zoom: 1;
  text-align: right;
  z-index: 8;
}

.footer .backtop-icon img {
  width: 45px;
  height: 45px;
}

.ahover {
  position: relative;
}

.ahover a {
  position: relative;
}

.ahover a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.ahover a:after {
  bottom: -1px;
  display: block;
  height: 1px;
  width: 0%;
  content: "";
  background-color: #02a3af;
  left: 50%;
}

@media all and (min-width: 960px) {
  .ahover a:hover {
    opacity: 1;
    color: #02a3af;
  }

  .ahover a:hover:after {
    opacity: 1;
    width: 100%;
    left: 0;
  }
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}

.btnpage {
  margin: 0 auto;
  width: 100%;
  max-width: 210px;
  margin-top: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media all and (max-width: 768px) {
  .btnpage {
    margin-top: 20px;
    max-width: 180px;
  }
}
.btnpage a,
.btnpage button {
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  color: #fff;
  position: relative;
  position: relative;
  background-image: -ms-linear-gradient(-3deg, #f89b00 0%, #f8c600 100%);
  z-index: 0;
  overflow: hidden;
}

.btnpage a::after,
.btnpage button::after {
  position: absolute;
  content: "";
  background-color: #02a3af;
  width: 0;
  height: 60px;
  right: 0;
  top: 0;
  -webkit-transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@media all and (min-width: 960px) {
  .btnpage a:hover::after,
  .btnpage a:focus::after,
  .btnpage a:active::after,
  .btnpage button:hover::after,
  .btnpage button:focus::after,
  .btnpage button:active::after {
    right: inherit;
    left: 0;
    width: 100%;
    z-index: 0;
  }
}
@media all and (max-width: 768px) {
  .btnpage a,
  .btnpage button {
    font-size: 18px;
    padding: 5px 0;
  }
}
.btnpage a span,
.btnpage button span {
  position: relative;
  z-index: 2;
  position: relative;
}

.btnpage a span::before,
.btnpage button span::before {
  content: "";
  background: transparent;
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 50%;
  margin-top: -4px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btnpage a span::before,
.btnpage button span::before {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  background: transparent;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  right: 50%;
  margin-right: -50px;
}

.btnpage.btnback {
  margin-top: 0;
  margin-left: 0;
}

.btnpage.btnback a span {
  position: relative;
}

.btnpage.btnback a span::before {
  content: "";
  background: transparent;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 0;
  top: 50%;
  margin-top: -4px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btnpage.btnback a span::before {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  background: transparent;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  right: inherit;
  left: 0%;
  margin-left: -50px;
  -webkit-transform: rotate(-135deg) !important;
  transform: rotate(-135deg) !important;
}

.btnpage.btnnext {
  margin-top: 0;
  margin-right: 0;
}

.fss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fsc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fcc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fsp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.fwb {
  font-weight: bold;
}

.fwn {
  font-weight: normal;
}

.fwl {
  font-weight: 300;
}

.ctboth {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.ctver {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.cthor {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.relative {
  position: relative;
}

.lsp05 {
  letter-spacing: 0.5px;
}

.lsp1 {
  letter-spacing: 1px;
}

.lsp15 {
  letter-spacing: 1.5px;
}

.lsp2 {
  letter-spacing: 2px;
}

.date * {
  background: #ffffff !important;
  border: none !important;
}

.date .input-group .form-control {
  -webkit-box-shadow: initial !important;
  box-shadow: initial !important;
}

.date .input-group-addon {
  width: 50px;
  background: #ffffff url("../images/calendar.png") no-repeat center center !important;
  background-size: 25px !important;
}

.date .input-group-addon .glyphicon-calendar {
  display: none !important;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #02a3af !important;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #02a3af !important;
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #02a3af !important;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #0c0a0a;
}

body .maincl {
  color: #02a3af;
}

body a {
  color: #0c0a0a;
  line-height: normal;
}

body img,
body {
  max-width: 100%;
  line-height: 0;
}

body .img {
  overflow: hidden;
  line-height: 0;
}

body .img img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 4s;
  transition-duration: 4s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: transform, -webkit-transform;
}

.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flexbox_start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flexbox_end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flexbox_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

html {
  overflow-x: hidden;
  font-size: unset !important;
}

a:focus,
a:hover {
  text-decoration: none !important;
}

.btn-outline-danger:hover {
  color: #fff !important;
}

@media all and (min-width: 1200px) and (max-width: 1200px) {
  body {
    min-width: 1200px;
  }
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #1f1074;
}

.bg-dark {
  background-color: #333 !important;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  -ms-flex-preferred-size: 340px;
  flex-basis: 340px;
}

@media all and (min-width: 1199px) {
  #sidebarToggle {
    display: none;
  }
}
#layoutSidenav #layoutSidenav_nav {
  -ms-flex-preferred-size: 340px;
  flex-basis: 340px;
}

@media all and (max-width: 1600px) {
  #layoutSidenav #layoutSidenav_nav {
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
  }
}
.sb-topnav .navbar-brand {
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  padding: 10px;
  font-size: 16px;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link.nav-link.active {
  background-color: #ffffff;
  color: #02a3af;
  border-radius: 5px;
}

@media all and (min-width: 1200px) {
  .sb-sidenav .sb-sidenav-menu .nav .nav-link:hover {
    background-color: #37d0db;
    border-radius: 5px;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #02a3af !important;
}

.navbar {
  margin-bottom: 0 !important;
  height: 60px;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar img,
.navbar span {
  display: inline-block;
}

@media all and (min-width: 1600px) {
  .navbar {
    padding: 0 40px;
  }
}
@media all and (max-width: 1199px) {
  .navbar {
    padding: 0 20px;
  }
}
.btn-outline-info:hover {
  background-color: #0dcaf0;
  color: #fff;
}

.sb-sidenav .sb-sidenav-menu .nav {
  border-radius: 5px;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link:not(:last-child) {
  border-bottom: 1px solid #16c6d6;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  color: #ffffff;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link img {
  margin-right: 10px;
}

/* thuy sc */
.btn-group2 .btn-main:first-child {
  margin-right: 20px;
}

@media all and (max-width: 639px) {
  .btn-group2 .btn-main:first-child {
    margin-right: 10px;
  }
}
.w30px {
  width: 30px !important;
}

.m-w150 {
  max-width: 150px;
}

.w100px {
  width: 100px !important;
}

.w180px {
  width: 180px !important;
}

.w300px {
  width: 300px !important;
}

.toggle-password {
  cursor: pointer;
}

.btn-primary {
  background: #02a3af !important;
  border: 1px solid #02a3af !important;
  border-radius: 8px !important;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.br8 {
  border-radius: 8px !important;
  border: 1px solid #02a3af !important;
}

@media all and (min-width: 1200px) {
  .btn-primary:hover {
    background-color: #37d0db !important;
    border-color: #37d0db !important;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
}
.btn-info,
.btn-warning {
  color: #fff !important;
}

.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-dark:hover {
  color: #fff !important;
  background-color: #23272b !important;
  border-color: #1d2124 !important;
}

.text-underline {
  color: #02a3af;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media all and (min-width: 1200px) {
  .text-underline:hover {
    text-decoration: underline !important;
    color: #02a3af;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
}
.dataTable-table > tbody > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: middle;
  text-align: center;
}

.dataTable-table > thead > tr > th {
  background: #f3f1f1;
}

.dataTable-table > tbody > tr > td {
  background: #ffffff;
}

.dataTable-table {
  border-color: #e9e8e8;
  border-radius: 10px;
  margin-bottom: 0;
}

.dataTable-table td .fcc a {
  border-radius: 3px !important;
  padding: 0.075rem 0.75rem;
  font-size: 14px;
  width: 54px;
}

.dataTable-table td .fcc a.btnsend {
  width: inherit;
  min-width: 85px;
  padding: 0.075rem 0.35rem;
}

.dataTable-table td .fcc a:not(:last-child) {
  margin-right: 10px;
}

.dataTable-table > tbody > tr:hover {
  --bs-table-accent-bg: transparent;
}

@media all and (max-width: 639px) {
  .dataTable-container {
    overflow-x: scroll;
  }

  .dataTable-container .dataTable-table,
  .dataTable-container .table {
    min-width: 1000px;
  }
}
.btn-group-end a {
  border-radius: 3px !important;
  padding: 0.075rem 0.75rem;
  font-size: 14px;
}

.btn-group-end a:first-child {
  margin-right: 10px;
}

.boxpassword {
  position: relative;
}

.toggle-password {
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.table_detail > tbody > tr:first-child td {
  border-top: none !important;
}

.table_detail > tbody > tr > td {
  vertical-align: middle !important;
  padding: 20px !important;
}

.table_detail > tbody > tr > td:first-child {
  width: 15%;
  min-width: initial;
}

.dropdown-toggle {
  position: relative;
}

.dropdown-toggle::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto 0;
  height: 5px;
}

/* page login */
.page-login .breadcrumb {
  display: none;
}

.p-login {
  background: #02a3af url("../images/login_bg.png") no-repeat bottom center;
}

.p-login #layoutSidenav {
  padding: 0;
  background: #02a3af url("../images/login_bg.png") no-repeat bottom center;
}

.p-login #layoutSidenav #layoutSidenav_content {
  margin-left: 0;
}

.p-login .login-top {
  margin: 20px;
}

.p-login .login-top .item {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  z-index: 2;
  position: relative;
}

.p-login .login-top .green-info {
  margin-top: 0;
  background: #00616b;
  color: #ffffff;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 24px 10px 14px 10px;
  position: relative;
  top: -10px;
  z-index: 1;
}

@media all and (max-width: 639px) {
  .p-login .login-top .green-info {
    font-size: 13px;
    padding: 18px 10px 8px 10px;
  }
}
.p-login .login-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-login .logobox {
  margin-bottom: 60px;
}

@media all and (max-width: 639px) {
  .p-login .logobox {
    margin-bottom: 30px;
  }

  .p-login .logobox img {
    height: 150px;
  }
}
.p-login main {
  height: 100%;
}

.p-login .loginbox_c {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  padding: 35px 65px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 5vh;
}

@media all and (min-width: 1600px) {
  .p-login .loginbox_c {
    padding: 70px 130px;
    width: 668px;
  }
}
@media all and (max-width: 1199px) {
  .p-login .loginbox_c {
    padding: 5vw;
  }
}
@media all and (max-width: 639px) {
  .p-login .loginbox_c {
    width: 100%;
  }
}
.p-login label {
  font-weight: bold;
}

.p-login .btn-primary {
  width: 100%;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
}

@media all and (max-width: 639px) {
  .p-login .btn-primary {
    height: 50px;
    font-size: 16px;
  }
}
.p-login .formlogin .formbox_e:first-child {
  margin-bottom: 30px;
}

@media all and (max-width: 1199px) {
  .p-login .formlogin .formbox_e:first-child {
    margin-bottom: 20px;
  }
}
.p-login .boxbtn {
  margin-top: 40px;
}

@media all and (max-width: 639px) {
  .p-login .boxbtn {
    margin-top: 30px;
  }
}
.p-login a {
  color: #02a3af;
  text-decoration: none;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media all and (min-width: 1200px) {
  .p-login a:hover {
    text-decoration: underline !important;
    color: #02a3af;
    opacity: 0.8;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
}
@media all and (max-width: 639px) {
  .p-login a {
    font-size: 13px;
  }
}
.p-login .resetpass {
  position: relative;
}

.p-login .resetpass h2.title {
  font-size: 24px;
  color: #02a3af;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}

@media all and (max-width: 639px) {
  .p-login .resetpass h2.title {
    font-size: 20px;
  }
}
.p-login .resetpass p.text_note {
  margin-bottom: 30px;
}

@media all and (max-width: 639px) {
  .p-login .resetpass p.text_note {
    font-size: 13px;
  }
}
.p-login .resetpass .btn-left {
  position: absolute;
  top: 30px;
  left: 30px;
}

.p-login .resetpass .btn-left a,
.p-login .resetpass .btn-left button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #02a3af;
  display: block;
  position: relative;
  background: #ffffff;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.p-login .resetpass .btn-left a::before,
.p-login .resetpass .btn-left button::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border: 1px solid #02a3af;
  border-width: 2px 0 0 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0;
  bottom: 0;
  left: 20px;
  margin: auto 0;
}

.p-login .resetpass .btn-left a:hover,
.p-login .resetpass .btn-left button:hover {
  background: #02a3af;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.p-login .resetpass .btn-left a:hover::before,
.p-login .resetpass .btn-left button:hover::before {
  border-color: #fff;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media all and (max-width: 639px) {
  .p-login .resetpass .btn-left {
    top: 10px;
    left: 10px;
  }

  .p-login .resetpass .btn-left a,
  .p-login .resetpass .btn-left button {
    width: 40px;
    height: 40px;
  }

  .p-login .resetpass .btn-left a::before,
  .p-login .resetpass .btn-left button::before {
    width: 10px;
    height: 10px;
    left: 15px;
  }
}
.p-login .otp-block {
  width: 100%;
  max-width: 1000px;
  padding: 65px;
}

.p-login .otp-block h2.title {
  margin-bottom: 30px;
}

.p-login .otp-block p.text_note {
  margin-bottom: 20px;
}

.p-login .otp-block .number {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-login .otp-block .number-box {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}

.p-login .otp-block .number-box .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 76px;
  height: 76px;
  margin: 10px;
  text-align: center;
  position: relative;
}

.p-login .otp-block .number-box .item input {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #02a3af;
  border-radius: 3px;
  color: #02a3af;
  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  font-weight: 700;
}

.p-login .otp-block .number-box .item input:focus,
.p-login .otp-block .number-box .item input:hover,
.p-login .otp-block .number-box .item input:active {
  border: 1px solid #02a3af;
  outline: none;
}

.p-login .otp-block .number-box .item:nth-of-type(n + 2)::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 1px;
  background: #0c0a0a;
  font-weight: normal;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  margin-left: -13px;
}

@media all and (max-width: 639px) {
  .p-login .otp-block .number-box .item {
    width: 60px;
    height: 60px;
  }
}
@media all and (max-width: 1024px) {
  .p-login .otp-block {
    width: 80%;
  }
}
@media all and (max-width: 639px) {
  .p-login .otp-block {
    width: 100%;
    padding: 70px 10px;
  }
}
@media all and (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
  }
}
/* button style */
.btn-pdf {
  border: 1px solid #02a3af;
  border-radius: 8px;
  min-width: 177px;
  font-weight: bold;
  padding: 25px 20px;
  margin-left: auto;
  margin-right: 0;
  background: url("../common_img/pdf.png") no-repeat left 15px center;
  background-size: 43px;
}

.btn-pdf span {
  padding-left: 50px;
  color: #02a3af;
}

@media all and (min-width: 1200px) {
  .btn-pdf:hover {
    background-color: #37d0db;
    border-color: #37d0db;
  }

  .btn-pdf:hover span {
    color: #ffffff;
  }
}
.btn-success {
  background-color: #02a3af !important;
  border-color: #02a3af !important;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.btn-success:hover {
  background-color: #37d0db !important;
  border-color: #37d0db !important;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.btn-danger {
  background-color: #f34848 !important;
  border-radius: 8px !important;
}

.btn-danger:hover {
  background-color: #ac2925 !important;
  border-color: #761c19 !important;
}

.btn-outline-primary {
  border-color: #02a3af !important;
  color: #02a3af;
  border-radius: 100px;
}

.btn-outline-primary:hover {
  background-color: #37d0db;
  border-color: #37d0db !important;
}

.btnpdf-wrapper input[type="file"] {
  display: none;
}

.btnpdf-wrapper .custom-button {
  margin-right: 10px;
}

/* common style */
.ms-options-wrap > button:focus,
.ms-options-wrap > button {
  color: #0c0a0a !important;
}

.w200 {
  width: 200px !important;
}

.table,
.dataTable-table {
  color: #0c0a0a;
}

.form-control {
  background: #f2f5f5 !important;
  border-color: #f2f5f5 !important;
  -webkit-box-shadow: initial !important;
  box-shadow: initial !important;
  color: #0c0a0a !important;
  font-size: 16px !important;
}

.btn-main {
  min-width: 180px;
  font-size: 16px !important;
  padding: 0.375rem 0.75rem !important;
}

@media all and (min-width: 1600px) {
  .btn-main {
    min-width: 248px;
  }
}
@media all and (max-width: 639px) {
  .btn-main {
    min-width: 120px;
    font-size: 14px;
  }
}
.page-link {
  border-color: #e9e8e8;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
}

.page-link:hover,
.dataTable-pagination a:hover,
.page-link:focus,
.dataTable-pagination a:focus {
  background-color: #37d0db;
  color: #ffffff;
  border-color: #37d0db;
}

.page-item.disabled .page-link,
.page-item.disabled .dataTable-pagination a,
.dataTable-pagination .page-item.disabled a,
.dataTable-pagination li.disabled .page-link,
.dataTable-pagination li.disabled a {
  background: #02a3af;
  color: #ffffff;
  border-color: #02a3af;
}

.pagination li:not(:last-child) {
  margin-right: 10px;
}

footer {
  display: none !important;
}

#layoutSidenav {
  padding: 20px;
  background: #f5f8fa;
}

@media all and (min-width: 1600px) {
  #layoutSidenav {
    padding: 40px;
  }
}
#layoutSidenav #layoutSidenav_content {
  margin-left: 30px;
}

#layoutSidenav #layoutSidenav_content .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

#layoutSidenav #layoutSidenav_content .content-wrapper {
  padding: 25px 30px;
  background: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media all and (min-width: 1600px) {
  #layoutSidenav #layoutSidenav_content .content-wrapper {
    padding: 50px 60px;
  }
}
@media all and (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_content .content-wrapper {
    padding: 2vw;
  }
}
#layoutSidenav #layoutSidenav_content .content-wrapper .card {
  margin-bottom: 20px;
}

#layoutSidenav #layoutSidenav_content h1 {
  margin-bottom: 0;
  background: #02a3af;
  color: #ffffff;
}

@media all and (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_content {
    margin-left: -300px;
  }
}
@media all and (max-width: 639px) {
  #layoutSidenav {
    padding: 10px;
  }
}
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0 !important;
}

.navbar-brand img {
  margin-right: 10px;
}

.navbar-brand span {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.px-4 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.text-right {
  text-align: right;
}

.form-control,
.dataTable-input {
  background: #ffffff;
  border: none;
  border-radius: 4px;
  color: #c7c7c7;
  padding: 0.5rem 1rem;
}

.form-control::-webkit-input-placeholder,
.dataTable-input::-webkit-input-placeholder {
  color: #898989;
}

.form-control::-moz-placeholder,
.dataTable-input::-moz-placeholder {
  color: #898989;
}

.form-control:-ms-input-placeholder,
.dataTable-input:-ms-input-placeholder {
  color: #898989;
}

.form-control::-ms-input-placeholder,
.dataTable-input::-ms-input-placeholder {
  color: #898989;
}

.form-control::-webkit-input-placeholder,
.dataTable-input::-webkit-input-placeholder {
  color: #898989;
}

.form-control::-moz-placeholder,
.dataTable-input::-moz-placeholder {
  color: #898989;
}

.form-control:-ms-input-placeholder,
.dataTable-input:-ms-input-placeholder {
  color: #898989;
}

.form-control::-ms-input-placeholder,
.dataTable-input::-ms-input-placeholder {
  color: #898989;
}

.form-control::placeholder,
.dataTable-input::placeholder {
  color: #898989;
}

.select-style {
  min-width: 120px;
  position: relative;
}

.select-style::before {
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 0;
  border: 6px solid rgba(0, 0, 0, 0);
  border-top-color: #999;
  margin-top: -3px;
}

.select-style .form-control {
  background: #ffffff !important;
}

/**/
.p-notice_operator .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #e9e8e8;
  border-top: 1px solid #e9e8e8;
  color: #ababab;
  padding: 20px 0;
  margin-bottom: 20px;
}

.p-notice_operator .title h3 {
  margin-bottom: 0;
  font-size: 16px;
  width: calc(100% - 115px);
}

@media all and (max-width: 639px) {
  .p-notice_operator .title h3 {
    width: calc(100% - 96px);
  }
}
.p-notice_operator .title .date {
  width: 115px;
}

@media all and (max-width: 639px) {
  .p-notice_operator .title .date {
    width: 86px;
    margin-right: 10px;
  }
}
.p-notice_operator .card {
  border: none;
}

.p-notice_operator .card p {
  font-size: 16px;
  text-align: justify;
}

.p-notice_operator .card-img {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-notice_operator .card-img img {
  width: 290px;
  margin-right: 30px;
}

@media all and (max-width: 1199px) {
  .p-notice_operator .card-img img {
    width: 100%;
    margin-right: 0;
  }
}
@media all and (max-width: 639px) {
  .p-notice_operator .card-img img {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
}
.p-notice_operator .card-img .text {
  width: calc(100% - 320px);
  max-width: 70%;
}

@media all and (max-width: 1199px) {
  .p-notice_operator .card-img .text {
    width: 66%;
    max-width: 66%;
  }

  .p-notice_operator .card-img .image {
    width: 32%;
  }
}
@media all and (max-width: 639px) {
  .p-notice_operator .card-img .text {
    width: 100%;
  }
}
/**/
.p-morthodontist .tag {
  padding: 0.13rem 0.75rem;
  color: #ffffff;
  border-radius: 3px;
  display: inline-block;
  line-height: 1.5;
  width: 54px;
  font-size: 14px;
}

.p-morthodontist .orange-tag {
  background: #ff7e07;
}

.p-morthodontist .yellow-tag {
  background: #ffc107;
}

@media all and (min-width: 1600px) {
  .p-morthodontist .select-style {
    width: 100%;
  }
}
.p-morthodontist .dataTable-table thead th:first-child,
.p-morthodontist .dataTable-table thead th:nth-child(2) {
  max-width: 280px;
  min-width: 280px;
}

h1 {
  background: #02a3af;
  color: #ffffff;
  font-size: 24px;
  padding: 15px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media all and (max-width: 639px) {
  h1 {
    font-size: 18px;
    padding: 10px 15px;
  }
}
.card {
  border: none;
}

.card .card-header {
  background: #02a3af;
  color: #ffffff;
  font-size: 24px;
  padding: 15px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media all and (max-width: 639px) {
  .card .card-header {
    font-size: 18px;
    padding: 10px 15px;
  }
}
.card .card-body {
  padding: 20px;
  background: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media all and (min-width: 1600px) {
  .card .card-body {
    padding: 20px 40px;
  }
}
@media all and (max-width: 639px) {
  .card .card-body {
    padding: 15px;
  }
}
.ms-options-wrap ul {
  padding-left: 0;
  margin-bottom: 0;
}

.ms-options-wrap ul li {
  list-style: none;
}

.iprup {
  min-width: 170px;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.sb-topnav .navbar-brand {
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media all and (max-width: 639px) {
  .navbar {
    padding: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #03a3ae;
  }

  .navbar .navbar-brand {
    width: calc(100% - 40px);
    font-size: 13px;
  }

  .navbar .navbar-brand img {
    height: 40px;
    margin-right: 0;
  }
}
@media all and (max-width: 1024px) {
  .sb-sidenav-toggled #layoutSidenav {
    padding: 0;
  }
}
.cl-red {
  fill: #f34848;
}

/* medical_management */
.p-medical_management .iprup {
  width: 200px;
}

.p-medical_management .iprup input.form-control {
  width: calc(100% - 54px);
}

.p-medical_management .iprup .btn-success {
  width: 54px;
}

.p-medical_management .datepick {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-medical_management .datepick .date {
  width: 200px;
}

.p-medical_management .datepick span {
  margin: 0 5px;
}

.p-medical_management .teeth-wrapper svg g {
  display: inline-block;
  cursor: pointer;
}

@media all and (min-width: 1200px) {
  .p-medical_management .teeth-wrapper svg g:hover path:first-child {
    fill: #aaa !important;
  }
}
.p-medical_management .teeth-wrapper svg g.b-tooth path:first-child {
  fill: #3f3e3e !important;
}

@media all and (max-width: 639px) {
  .p-medical_management .teeth-wrapper svg {
    height: 250px;
  }
}
.p-medical_management .table-detailbox {
  border-spacing: 0 10px;
  border-collapse: separate;
}

.p-medical_management .table-detailbox th {
  vertical-align: middle;
  border-left: 1px solid #dee2e6;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-medical_management .table-detailbox th,
.p-medical_management .table-detailbox td {
  padding: 15px 10px;
  border-top: 1px solid #dee2e6;
}

.p-medical_management .table-detailbox tr td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #dee2e6;
}

.p-medical_management .modal-content {
  padding: 20px;
}

.p-medical_management .modal-content .modal-header,
.p-medical_management .modal-content .modal-body,
.p-medical_management .modal-content .modal-footer {
  padding: 0;
}

.p-medical_management .modal-content .modal-header .close,
.p-medical_management .modal-content .modal-body .close,
.p-medical_management .modal-content .modal-footer .close {
  background: none;
}

.p-medical_management .modal-content .modal-header .close svg,
.p-medical_management .modal-content .modal-body .close svg,
.p-medical_management .modal-content .modal-footer .close svg {
  color: #02a3af;
  font-size: 20px;
}

.p-medical_management .modal-content .modal-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom: none;
}

.p-medical_management .modal-content .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: none;
}

.p-medical_management .modal div.modal-dialog:not(.confirm-delete) .modal-content .modal-footer a {
  width: 100%;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  padding: 1.1rem 0.75rem;
}

.p-medical_management .position-custom .image-checkbox {
  cursor: pointer;
}

.p-medical_management .position-custom .image-checkbox input[type="checkbox"] {
  display: none;
}

.p-medical_management .position-custom .image-checkbox-checked svg path {
  fill: #f34848;
}

.p-medical_management .bootstrap-datetimepicker-widget {
  z-index: 99999 !important;
  position: absolute !important;
}

.p-medical_management .list-galerry figure {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.relative {
  position: relative;
}

.delete-btn {
  width: 30px;
  height: 30px;
  background-color: #fa8072;
  border-radius: 100px;
  position: absolute;
  text-align: center;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.delete-btn:hover {
  opacity: 0.6;
}

.close-icon {
  height: 15px;
  width: 15px;
  margin: 0 auto;
}

.p-medical_management .list-galerry .col-lg-3:nth-of-type(n + 4) {
  margin-top: 20px;
}

.p-medical_management .list-galerry input[type="file"] {
  display: none;
}

@media all and (min-width: 992px) {
  .p-medical_management .list-galerry label {
    min-width: 154px;
  }

  .p-medical_management .list-galerry .col-lg-3:nth-of-type(n + 4) {
    margin-top: 0;
  }

  .p-medical_management .list-galerry .col-lg-3:nth-of-type(n + 5) {
    margin-top: 20px;
  }
}
.p-medical_management .table-custom {
  width: 100%;
}

.p-medical_management .table-custom .text-bold {
  font-weight: 500;
}

.p-medical_management .table-custom tr th,
.p-medical_management .table-custom tr td {
  vertical-align: middle;
  text-align: center;
  padding: 10px 2px;
}

.p-medical_management .jaw-box .select-item-wrapper {
  width: 25%;
  position: relative;
}

.p-medical_management .jaw-box .select-item-wrapper::before {
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  border: 6px solid rgba(0, 0, 0, 0);
  border-top-color: #999;
  margin-top: -3px;
}

.p-medical_management .jaw-box label {
  width: 25%;
}

.p-medical_management .epp {
  padding: 0 !important;
}

.p-medical_management .epp .table-epp {
  width: 100%;
  margin-bottom: 0;
}

.p-medical_management .epp .table-epp tr:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.p-medical_management .epp .table-epp tr td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}

.p-medical_management .input-group .btn-success {
  padding: 0.375rem 0.7rem;
}

.p-medical_management .form-select-wrapper {
  position: relative;
}

.p-medical_management .form-select-wrapper::before {
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  border: 6px solid rgba(0, 0, 0, 0);
  border-top-color: #999;
  margin-top: -3px;
}

.p-medical_management .dataTable-container-scroll {
  overflow-x: scroll;
  padding-bottom: 20px;
}

.p-medical_management .dataTable-container-scroll .dataTable-table {
  width: 1200px;
}

.input-group .btn,
.ms-options-wrap > button:focus,
.ms-options-wrap > button,
.form-control {
  font-size: 1rem;
  height: 40px !important;
  border: none;
}

/* side bar */
.nav {
  background: #02a3af;
  border-radius: 10px;
  padding: 10px 10px 10px 10px !important;
}

.nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link bor img {
  margin-right: 10px;
}

@media all and (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_nav {
    -webkit-transform: translateX(-320px);
    transform: translateX(-320px);
  }
}
.input-group .form-control {
  background: #ffffff !important;
}

@media all and (min-width: 1600px) {
  .input-group .btn-success {
    padding: 0.375rem 0.7rem;
  }
}
.ms-options-wrap > button:focus,
.ms-options-wrap > button {
  border: none !important;
}

.table_detail .date * {
  background: #f2f5f5 !important;
}

.table_detail .date .input-group-addon {
  width: 50px;
  background: #f2f5f5 url("../images/calendar.png") no-repeat center center !important;
  background-size: 25px !important;
}

.table_detail .date .input-group-addon .glyphicon-calendar {
  display: none !important;
}

/**/
.bootstrap-datetimepicker-widget,
.usetwentyfour {
  position: absolute !important;
  z-index: 999 !important;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  padding: 1rem 0.5rem;
}

.dataTable-table > thead > tr > th {
  font-weight: normal;
}

.ms-options-wrap > .ms-options > ul label {
  font-size: 14px;
  color: #0c0a0a;
}

.time-row .item select {
  background: #f2f5f5 url("../images/calendar.png") no-repeat center right 5px !important;
  background-size: 30px !important;
  width: 80%;
  margin-right: 10px;
}

.dataTable-container-scroll {
  overflow-x: auto;
}

@media all and (max-width: 639px) {
  .p-login .login-top {
    margin: 0;
  }

  .p-login #layoutSidenav #layoutSidenav_content .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
}
button,
a {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.form-select-wrapper {
  position: relative;
}

.form-select-wrapper::before {
  content: "";
  height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  border: 6px solid rgba(0, 0, 0, 0);
  border-top-color: #999;
  margin-top: -3px;
}

/* checkbox custom */
.check-custom {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  height: 16px;
  width: 16px;
  background-color: #e9e8e8;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.check-custom:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-custom input:checked ~ .checkmark {
  background-color: #02a3af;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-custom input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-custom .checkmark:after {
  top: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* gray-check */
.gray-check .checkmark {
  background-color: #444444;
}

/* gray-check */
.pink-check .checkmark {
  background-color: #ffe6f0;
}

/* gray-check */
.red-check .checkmark {
  background-color: #e10808;
}

.gray-check input:checked ~ .checkmark {
  background-color: #444444;
}

.pink-check input:checked ~ .checkmark {
  background-color: #ffe6f0;
}

.red-check input:checked ~ .checkmark {
  background-color: #e10808;
}

.img-wrapper.disabled .btn-outline-primary {
  pointer-events: none;
}

.teeth-wrapper.disabled .tooth {
  pointer-events: none;
}

.p-medical_management .list-galerry label {
  display: block;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.p-notice_operator .content-body .card .image {
  margin-right: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27%;
  flex: 0 0 27%;
}
.p-notice_operator .content-body .card .image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-right: 0;
}
@media (max-width: 1199px) {
  .p-notice_operator .content-body .card .image img {
    margin-bottom: 0;
  }
}
@media (max-width: 1199px) {
  .p-notice_operator .content-body .card .image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    margin-right: 0;
  }
}
.p-notice_operator .content-body .card .text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
}
@media (max-width: 1199px) {
  .p-notice_operator .content-body .card .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
  }
}
@media (max-width: 1199px) {
  .p-notice_operator .content-body .card .flexbox {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .p-notice_operator .content-body .card .flexbox p {
    word-break: break-all;
  }
}
.p-notice_operator .content-body .card-noimg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.p-notice_operator .content-body .card-noimg .flexbox {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.p-notice_operator .content-body .card-noimg .flexbox .text,
.p-notice_operator .content-body .card-noimg .flexbox .title,
.p-notice_operator .content-body .card-noimg .flexbox .linewrap {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
@media (max-width: 1199px) {
  .p-notice_operator .content-body .card-noimg .text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 1199px) {
  #layoutSidenav #layoutSidenav_content .content-wrapper .card-noimg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  #layoutSidenav #layoutSidenav_content .content-wrapper .card-noimg .flexbox {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .card .card-body {
    padding: 15px;
  }

  body table th,
  body table td {
    font-size: 14px;
  }

  .card .card-header {
    font-size: 20px;
  }
}
.date-fix .align-center {
  width: calc(100% - 50px);
}
.date-fix .rdtPicker td,
.date-fix .rdtPicker th {
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}
.date-fix .rdtPicker .rdtDay.rdtActive {
  background: #02a3af !important;
  color: #fff;
}

.csvheader,
.texttit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
}

.texttit div {
  margin-right: 5px;
  cursor: pointer;
}

.csvdownload {
  margin-right: 0 !important;
}
.csvdownload button {
  background: #000;
  color: #fff;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.csvdownload button img {
  margin-right: 10px;
}
.csvdownload button:hover {
  opacity: 0.7;
  color: #fff;
}

.hassmalltext span {
  font-size: 12px;
  color: #717171;
  display: block;
}

.zipcode .inputzip {
  min-width: 270px;
}
.zipcode button {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  height: 40px;
  outline: none;
}
.zipcode button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.qrCode-box {
  background-color: white;
  padding: 1.5rem;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-qrcode-viewer .modal-content {
  padding: 20px;
}
.modal-qrcode-viewer .modal-content .modal-header,
.modal-qrcode-viewer .modal-content .modal-body,
.modal-qrcode-viewer .modal-content .modal-footer {
  padding: 0;
}

.modal-qrcode-viewer .modal-content .modal-header .close,
.modal-qrcode-viewer .modal-content .modal-body .close,
.modal-qrcode-viewer .modal-content .modal-footer .close {
  background: none;
}

.modal-qrcode-viewer .modal-content .modal-header .close svg,
.modal-qrcode-viewer .modal-content .modal-body .close svg,
.modal-qrcode-viewer .modal-content .modal-footer .close svg {
  color: #02a3af;
  font-size: 20px;
}

.modal-qrcode-viewer .modal-content .modal-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom: none;
}

.modal-qrcode-viewer .modal-content .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: none;
}

.file-management .search-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-management .search-bar-container .search-bar {
  width: 30%;
  display: flex;
  transition: width 0.5s;
}
.file-management .search-bar-container .search-bar:has(.search-input:focus) {
  width: 50%;
}
.file-management .search-bar-container .search-bar .search-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.file-management .search-bar-container .search-bar .search-input-glass {
  background-color: #f2f5f5;
  padding-right: 1rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
}
.file-management .search-bar-container .search-bar .search-input-glass img {
  cursor: pointer;
}
.file-management .search-bar-container .search-bar:has(.search-input:focus) .search-input-glass img {
  filter: invert(41%) sepia(94%) saturate(1684%) hue-rotate(152deg) brightness(93%) contrast(98%);
}
@media all and (max-width: 768px) {
  .file-management .search-bar-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .file-management .search-bar-container .search-bar {
    width: 100%;
  }
  .file-management .search-bar-container .search-bar:has(.search-input:focus) {
    width: 100%;
  }
}
.file-management .btn-upload-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.file-management .btn-upload-file::before {
  content: url("../common_img/upload_2.svg");
  transform: scale(0.5);
  margin-right: 1rem;
}
.file-management .btn-upload-folder-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.file-management .btn-upload-folder::before {
  content: url("../common_img/upload_2.svg");
  transform: scale(0.5);
  margin-right: 1rem;
}
.file-management .btn-create-folder::before {
  content: url("../common_img/folder.svg");
  transform: scale(0.5);
  margin-right: 1rem;
}
.file-management button .loading-center {
  width: 25px !important;
  height: 25px !important;
}
.file-management .breadcrumb {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.file-management .breadcrumb .breadcrumb-back {
  margin-right: 0.5rem;
  cursor: pointer;
}
.file-management .breadcrumb span {
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.file-management .breadcrumb span:hover {
  color: black;
}
.file-management .breadcrumb span::before {
  content: ">";
  margin: 0 0.5rem;
}
.file-management .breadcrumb span:first-child::before {
  content: none;
}
.file-management .breadcrumb span:nth-child(2)::before {
  content: none;
}
.file-management .folder-viewer,
.file-management .file-viewer {
  margin: 1.5rem 0;
}
.file-management .folder-container {
  margin-top: 1rem;
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  grid-gap: 1rem;
  overflow-x: auto;
}
.file-management .folder-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.file-management .folder-container::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
}
.file-management .folder-container::-webkit-scrollbar-thumb {
  background-color: #02a3af;
}
.file-management .folder-component {
  width: 220px;
  height: 44px;
  background-color: #81d1d7;
  padding: 0 0 0 0.75rem;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-management .folder-component-popup {
  width: 100%;
  height: 100%;
  min-height: 245px;
  padding: 0.75rem;
  flex-direction: column;
}
.file-management .folder-component .folder-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-management .folder-component::before {
  content: url("../common_img/folder_2.svg");
  margin-right: 0.5rem;
}
.file-management:has(.folder-component-popup) .folder-component::before {
  content: "";
  margin-right: 0;
}
.file-management .folder-component.selected {
  color: #02a3af !important;
  background-color: white !important;
}
.file-management .dropdown-dots::before {
  content: "\2807";
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}
.file-management .folder-component.selected .dropdown-dots::before {
  color: #02a3af !important;
}
.file-management .file-viewer .file-action {
  display: flex;
  justify-content: space-between;
}

.file-action-container {
  display: none;
}
.file-action-container img {
  filter: invert(41%) sepia(94%) saturate(1684%) hue-rotate(152deg) brightness(93%) contrast(98%);
  margin: 0 0.5rem;
  cursor: pointer;
}

.file-management .grid-container {
  grid-template-columns: repeat(2, calc(50% - 0.85rem));
  grid-gap: 1rem;
}
@media only screen and (min-width: 768px) {
  .file-management .grid-container {
    grid-template-columns: repeat(3, calc(33.33% - 0.85rem));
    grid-gap: 1rem;
  }
}
@media only screen and (min-width: 992px) {
  .file-management .grid-container {
    grid-template-columns: repeat(4, calc(25% - 0.85rem));
    grid-gap: 1rem;
  }
}
@media only screen and (min-width: 1536px) {
  .file-management .grid-container {
    grid-template-columns: repeat(5, calc(20% - 0.85rem));
    grid-gap: 1rem;
  }
}
@media only screen and (min-width: 1920px) {
  .file-management .grid-container {
    grid-template-columns: repeat(6, calc(16.66% - 0.85rem));
    grid-gap: 1rem;
  }
}
.file-management .grid-item {
  background: rgba(2, 163, 175, 0.5);
  padding: 5px !important;
  border: 1px solid #dbdbdb;
  margin: 0 !important;
  position: relative;
}
.file-management .grid-item .dropdown-dots {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
.file-management .grid-item .select-box {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.file-management .grid-item .grid-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  cursor: pointer;
}
.file-management .grid-item .grid-head {
  background-color: white;
  padding: 2rem 0 2rem 5px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.file-management .file-image {
  position: relative;
}
.file-management .file-image img {
  max-height: 100px;
}
.file-management .file-extension {
  width: 70%;
  font-size: 1.2rem;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-management .file-name {
  width: 100% !important;
  font-size: 14px;
  padding: 0.5rem 0 0.25rem 0;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
}
.file-management .grid-item .dropdown-dots::before {
  content: "\2807";
  font-size: 1.5rem;
  color: #cccccc;
}

.dropdown-action {
  background-color: white;
  padding: 0.25rem 0.5rem;
  border: 1px solid #e9e8e8;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 0;
  position: absolute;
  list-style-type: none;
  z-index: 10000;
}
.dropdown-action li {
  padding: 0.75rem 0.25rem;
  border-bottom: 0.5px solid #e9e8e8;
  cursor: pointer;
}
.dropdown-action li:last-child {
  border-bottom: none;
}
.dropdown-action li img {
  margin-right: 1rem;
}

.file-management .file-management-popup .modal-content {
  padding: 5px 10px 20px 10px;
  border-radius: 10px;
  border: none;
}
.file-management .file-management-popup .modal-header,
.file-management .file-management-popup .modal-body,
.file-management .file-management-popup .modal-footer {
  border: none;
  padding: 0;
}
.file-management .file-management-popup .modal-body {
  text-align: center;
  margin: 30px;
}
.file-management .file-management-popup .modal-footer {
  justify-content: center;
}
.file-management .file-management-popup .btn-group2 {
  min-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.file-management .file-management-popup .upload-file-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.file-management .file-management-popup .btn-group2 .btn {
  flex: 0.5;
  height: 2.5rem;
}
.file-management .file-management-popup .upload-file-path,
.file-management .file-management-popup .upload-folder-path {
  flex: 1;
  width: 100px;
  min-height: 2.5rem;
  padding: 0.35rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(239, 239, 239, 0.3);
  color: rgb(84, 84, 84);
  border: 1px solid rgba(118, 118, 118, 0.3);
}
.file-management .file-management-popup .upload-file-path span,
.file-management .file-management-popup .upload-folder-path span {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-management .file-management-popup button {
  border-radius: 8px !important;
}
.file-management .file-management-popup.file-management-move-into-folder-popup {
  max-width: 100%;
}
@media only screen and (min-width: 768px) {
  .file-management .file-management-popup.file-management-move-into-folder-popup {
    width: 80vw !important;
  }
}
@media only screen and (min-width: 1024px) {
  .file-management .file-management-popup.file-management-move-into-folder-popup {
    width: 60vw !important;
  }
}
.file-management-move-into-folder-popup .back-button {
  margin-right: 0.5rem;
  cursor: pointer;
}
.file-management-move-into-folder-popup .grid-container {
  grid-template-columns: repeat(4, calc(25% - 0.85rem));
  grid-gap: 1rem;
}
.file-management-move-into-folder-popup .grid-item {
  cursor: pointer;
}
.file-management-move-into-folder-popup .grid-item.selected {
  color: black !important;
  background-color: rgba(2, 163, 175, 0.5) !important;
}
.file-management-move-into-folder-popup .grid-item .grid-head > img {
  margin: 1rem;
}
.file-management-move-into-folder-popup .grid-item .file-name {
  color: white;
  text-align: left;
}

.file-management .storage-percent {
  margin-left: 1.25rem;
  display: inline-flex;
  flex-direction: column;
}
.file-management .storage-percent .storage-percent-line {
  width: 100%;
  height: 5px;
  background-color: grey;
  overflow: hidden;
}
.file-management .storage-percent .storage-percent-line .line {
  height: 5px;
}

.file-management .btn-call-api-upload {
  margin-left: 1.25rem;
}

.file-management-popup {
  min-width: 60vw;
  height: auto !important;
  max-height: unset !important;
}
.file-management-popup .modal-header,
.select-file-option-popup .modal-header {
  padding-bottom: 0;
  border-bottom: none;
}
.file-management-popup .modal-footer,
.select-file-option-popup .modal-footer {
  padding: 0;
  border: none;
}
.file-management-popup .modal-bodyViewer {
  max-height: unset;
}

.select-file-option-popup .btn-group2 {
  min-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.select-file-option-popup .btn-group2 .btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: auto;
}
.select-file-option-popup .btn-group2 .btn-main:first-child {
  margin-right: 0;
}
.select-file-option-popup .btn-group2 div:has(input[type="file"]) {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
