.p-analysis_management .desc-box .active,
.p-analysis_management .btn-border {
  border: 1px solid #02A3AF;
  color: #02A3AF;
  background: #ffffff !important;
}

.p-analysis_management .analysis-head {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(20% - 13.33px) 20px calc(60% - 13.33px) 20px calc(20% - 13.33px);
  grid-template-columns: calc(20% - 13.33px) calc(60% - 13.33px) calc(20% - 13.33px);
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.p-analysis_management .analysis-head h3 {
  margin: 0;
}

.p-analysis_management .analysis-head p {
  margin-bottom: 0;
}

.p-analysis_management .analysis-head-item:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #E6F8F8;
  border-radius: 20px;
  padding: 15px 10px 10px;
}

.p-analysis_management .analysis-head-item:first-child .center-box {
  text-align: center;
}

.p-analysis_management .analysis-head-item:first-child .center-box h3 {
  font-size: 16px;
  font-weight: 900;
  color: #02A3AF;
}

.p-analysis_management .analysis-head-item:first-child .center-box p {
  font-size: 16px;
  color: #79989A;
  margin-top: 5px;
  line-height: 1.7;
}

.p-analysis_management .analysis-head-item:first-child .center-box p span {
  display: block;
  font-weight: 900;
  color: #000000;
}

.p-analysis_management .analysis-head-item:nth-child(2) h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.p-analysis_management .analysis-head-item:nth-child(2) .calendar-box-item {
  width: calc(50% - 10px);
}

.p-analysis_management .analysis-head-item:nth-child(2) .calendar-box .date {
  background: #F2F5F5;
  border-radius: 6px;
  height: 60px;
}

.p-analysis_management .analysis-head-item:nth-child(2) .calendar-box .date input[type=text] {
  height: 100% !important;
  background: #F2F5F5 !important;
}

.p-analysis_management .analysis-head-item:nth-child(2) .calendar-box .date .input-group-addon {
  width: 60px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #F2F5F5 url(../images/calendar.png) no-repeat center center !important;
}

.p-analysis_management .analysis-head .btn-pdf {
  border: 1px solid #02A3AF;
  border-radius: 8px;
  width: 100%;
  font-weight: bold;
  padding: 35px 10px;
  background: url(../common_img/pdf.png) no-repeat left 15px center;
  background-size: 30px;
  font-size: 16px;
}

.p-analysis_management .analysis-body .shadow-box {
  -webkit-box-shadow: 0px 0.001px 30px 0px rgba(139, 138, 138, 0.1);
          box-shadow: 0px 0.001px 30px 0px rgba(139, 138, 138, 0.1);
}

.p-analysis_management .analysis-body .analysis-tab {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(25% - 15px) 15px calc(25% - 15px) 15px calc(25% - 15px) 15px calc(25% - 15px);
  grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0;
}

.p-analysis_management .analysis-body .analysis-tab li {
  display: block;
  height: 60px;
  background: #F2F5F5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  color: #02A3AF;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.p-analysis_management .analysis-body .analysis-tab .active {
  background: #02A3AF;
  color: #ffffff;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.p-analysis_management .analysis-body .tab-title {
  text-align: center;
  margin-bottom: 30px;
  background: #E6F8F8;
  font-size: 22px;
  font-weight: 500;
  border-radius: 20px;
  padding: 10px 40px;
  display: inline-block;
}

.p-analysis_management .analysis-body .tab-title span {
  position: relative;
  top: 2px;
  margin-left: 15px;
}

.p-analysis_management .analysis-body .tab-title-full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 25px;
  margin-bottom: 0;
}

.p-analysis_management .analysis-body .tab-box .graph-style1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(100% - 412px) 382px;
  grid-template-columns: calc(100% - 412px) 382px;
}

.p-analysis_management .analysis-body .tab-box .graph-style1 .item:first-child {
  background: #FBFBFB;
  border-radius: 40px;
  padding: 30px;
  text-align: center;
}

.p-analysis_management .analysis-body .tab-box .graph-style1 .item:nth-child(n+2) {
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.p-analysis_management .analysis-body .tab-box .graph-style1 .item:nth-child(n+2) .bottom-box .average-box {
  margin-top: 20px;
}

.p-analysis_management .analysis-body .tab-box .graph-style1 .item figure {
  text-align: center;
}

.p-analysis_management .analysis-body .tab-box .box-common {
  border-radius: 20px;
  padding: 30px 10px;
  margin-bottom: 0;
}

.p-analysis_management .analysis-body .tab-box .desc-box {
  border: 1px solid #E3EDED;
}

.p-analysis_management .analysis-body .tab-box .desc-box li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-analysis_management .analysis-body .tab-box .desc-box li .left {
  width: calc(100% - 106px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-analysis_management .analysis-body .tab-box .desc-box li .left span.square {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: block;
  border-radius: 3px;
}

.p-analysis_management .analysis-body .tab-box .desc-box li .left span.orange {
  background: #EE6A40;
}

.p-analysis_management .analysis-body .tab-box .desc-box li .left span.green {
  background: #02A3AF;
}

.p-analysis_management .analysis-body .tab-box .desc-box li .right {
  width: 96px;
  margin-left: 10px;
}

.p-analysis_management .analysis-body .tab-box .desc-box li:nth-child(n+1) {
  margin-top: 10px;
}

.p-analysis_management .analysis-body .tab-box .average-box {
  background: #02A3AF;
  margin-bottom: 0;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.p-analysis_management .analysis-body .tab-box .average-box li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
}

.p-analysis_management .analysis-body .tab-box .average-box .average-box-title {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffffff;
}

.p-analysis_management .analysis-body .tab-box .average-box .average-box-title img {
  margin-right: 10px;
}

.p-analysis_management .analysis-body .tab-box .average-box .average-box-ct {
  font-size: 60px;
  line-height: 1;
  font-weight: bold;
  margin-top: 10px;
}

.p-analysis_management .analysis-body .tab-box .bg-cover {
  background: #FBFBFB;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.p-analysis_management .analysis-body .tab-box .bg-cover .tab-content {
  padding: 40px;
}

.p-analysis_management .analysis-body .tab-box .bg-cover .align-center {
  text-align: center;
}

.p-analysis_management .analysis-body .comprehensive-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(33.33% - 13.67px) 25px calc(33.33% - 13.67px) 25px calc(33.33% - 13.67px);
  grid-template-columns: calc(33.33% - 13.67px) calc(33.33% - 13.67px) calc(33.33% - 13.67px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-column-gap: 25px;
  -moz-column-gap: 25px;
  column-gap: 25px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table {
  table-layout: fixed;
  width: 100%;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table th,
.p-analysis_management .analysis-body .comprehensive-box--ins table td {
  padding: 10px 5px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table tr th:first-child,
.p-analysis_management .analysis-body .comprehensive-box--ins table tr th:last-child {
  width: 40%;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table tr th:nth-child(2) {
  width: 20%;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table .green-txt {
  font-size: 22px;
  color: #02A3AF;
  line-height: 1;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table .green-txt span {
  font-size: 14px;
  color: #85898E;
  margin-left: 10px;
  font-weight: normal;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .bar {
  width: 100%;
  height: 44px;
  border-radius: 15px;
  background: #E6F8F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .bar .bar-percent {
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto 0;
  background: #02A3AF;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .bar span {
  position: relative;
  z-index: 9;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .green-bar {
  font-size: 22px;
  color: #02A3AF;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .purple-bar {
  background: #F3EFFF;
  color: #ffffff;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .purple-bar .bar-percent {
  background: #7B87F8;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .yellow-bar {
  background: #FFF9E2;
  color: #EABA0F;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .yellow-bar .bar-percent {
  background: #FEE58C;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .orange-bar {
  background: #FFE9E2;
  color: #EE6A40;
}

.p-analysis_management .analysis-body .comprehensive-box--ins .orange-bar .bar-percent {
  background: #EE6A40;
}

.p-analysis_management .analysis-body .comprehensive-box2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-analysis_management .analysis-body .comprehensive-box2 .comprehensive-box--ins {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 32%;
}

.p-analysis_management .analysis-body .comprehensive-box2 .comprehensive-box--ins p {
  margin-bottom: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  margin-right: 20px;
  font-size: 22px;
  font-weight: 500;
}

.p-analysis_management .analysis-body .comprehensive-box2 .comprehensive-box--ins .line-bar {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 44px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
}

.p-analysis_management .analysis-body .comprehensive-box2 .green .line-bar {
  background: #E6F8F8;
  color: #02A3AF;
}

.p-analysis_management .analysis-body .comprehensive-box2 .orange .line-bar {
  background: #FFE9E2;
  color: #EE6A40;
}

.p-analysis_management .analysis-body .comprehensive-box2 .purple .line-bar {
  background: #F3EFFF;
  color: #7B87F8;
}

.p-analysis_management .analysis-body .percent-box-custom {
  position: relative;
}

.p-analysis_management .analysis-body .percent-box-custom figure {
  text-align: left !important;
}

.p-analysis_management .analysis-body .percent-box {
  max-width: 380px;
  position: absolute;
  top: 50px;
  right: 50px;
}

.p-analysis_management .analysis-body .percent-box dl:not(:last-child) {
  margin-bottom: 50px;
}

.p-analysis_management .analysis-body .percent-box dt {
  font-size: 22px;
  font-weight: bold;
  border-radius: 20px;
  background: #F2F5F5;
  color: #7F8283;
  text-align: center;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.p-analysis_management .analysis-body .percent-box dd {
  color: #02A3AF;
  font-size: 60px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
}

@media (min-width: 1200px) {
  .p-analysis_management .analysis-head .btn-pdf:hover {
    background-color: #37d0db;
    border-color: #37d0db;
  }
}
@media (max-width: 1024px) {
  .p-analysis_management .analysis-head {
    -ms-grid-columns: calc(20% - 13.33px) calc(55% - 13.33px) calc(25% - 13.33px);
    grid-template-columns: calc(20% - 13.33px) calc(55% - 13.33px) calc(25% - 13.33px);
  }
}
@media (max-width: 960px) {
  .btn-pdf span {
    font-size: 14px;
  }

  .p-analysis_management .analysis-head .btn-pdf {
    background-size: 30px;
    background-position: left 10px center;
  }

  .p-analysis_management .analysis-body .tab-box .graph-style1 {
    display: block;
  }

  .p-analysis_management .bottom-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .p-analysis_management .bottom-box .average-box {
    width: 48%;
  }

  .p-analysis_management .analysis-body .comprehensive-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins {
    width: 48%;
    margin-right: 2%;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins:nth-child(2n) {
    margin-right: 0;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins:last-child {
    margin-right: 0;
    margin-top: 2%;
  }

  .p-analysis_management .analysis-body .tab-box .bg-cover .tab-content {
    padding: 20px;
  }

  .p-analysis_management .analysis-body .tab-box .graph-style1 .item:nth-child(n+2) {
    margin-left: 0;
  }
}
@media (max-width: 639px) {
  .p-analysis_management .analysis-head {
    display: block;
    margin-top: 0;
  }

  .p-analysis_management .analysis-head-item:nth-child(2) {
    margin: 20px 0;
  }

  .p-analysis_management .analysis-head-item:nth-child(2) .calendar-box-item {
    width: calc(50% - 5px);
  }

  .p-analysis_management .analysis-head-item:nth-child(2) h3 {
    font-size: 14px;
  }

  .p-analysis_management .analysis-head .btn-pdf {
    padding: 10px;
  }

  .p-analysis_management .analysis-body .analysis-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-column-gap: 0px;
    -moz-column-gap: 0px;
    column-gap: 0px;
  }

  .p-analysis_management .analysis-body .analysis-tab li {
    width: calc(50% - 5px);
    margin-right: 10px;
    height: 40px;
    font-size: 14px;
  }

  .p-analysis_management .analysis-body .analysis-tab li:nth-child(2n) {
    margin-right: 0;
  }

  .p-analysis_management .analysis-body .analysis-tab li:nth-child(n+3) {
    margin-top: 10px;
  }

  .p-analysis_management .analysis-body .tab-title {
    font-size: 18px;
  }

  .p-analysis_management .analysis-body .tab-box .box-common {
    padding: 10px 20px;
  }

  .p-analysis_management .bottom-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .p-analysis_management .bottom-box .average-box {
    width: 100%;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins {
    width: 100%;
    margin-right: 0;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins:nth-of-type(n+2) {
    margin-top: 20px;
  }

  .p-analysis_management .analysis-body .comprehensive-box--ins .bar {
    height: 28px;
  }

  .p-analysis_management .analysis-body .tab-box .average-box .average-box-ct {
    font-size: 40px;
  }

  .p-analysis_management .analysis-body .tab-box .average-box .average-box-title {
    font-size: 18px;
  }
}
.p-analysis_management .analysis-body .tab-box .box-common {
  padding: 20px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table td {
  font-size: 14px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table td:nth-child(2) {
  min-width: 40px;
}

.p-analysis_management .analysis-body .comprehensive-box--ins table {
  table-layout: auto;
}

.p-analysis_management .analysis-body .tab-title {
  padding: 10px 30px;
}

.p-analysis_management .analysis-head .btn-pdf {
  background-position: left 5px center;
  padding: 15px 0;
  padding-right: 5px;
}

.p-analysis_management .analysis-head .btn-pdf span {
  padding-left: 40px;
}

@media (max-width: 960px) {
  .p-analysis_management .bottom-box {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 1024px) {
  .p-analysis_management .analysis-head {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .p-analysis_management .analysis-head .analysis-head-item:first-child {
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-row: 1;
        grid-row-start: 1;
    -ms-grid-row-span: 0;
    grid-row-end: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    margin-bottom: 20px;
  }

  .p-analysis_management .analysis-head .analysis-head-item:last-child {
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-row: 2;
        grid-row-start: 2;
    -ms-grid-row-span: 2;
    grid-row-end: 4;
    -ms-grid-column-span: 2;
    grid-column-end: 4;
  }
}